<template>
  <div class="pageDetail">
    <div class="ziHead">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="ziNav">
      <div class="navMain">
        <div class="navMainLeft">
          <span :class="{onActive : active  == item.entTChannelUuid}" v-for="(item, index) in slideList" :key="index"  class="spanActive">
            <a class="navMainInner"
              v-if="!item.child.length && item.url && item.url !== ''&& item.entTChannelUuid !='01150003' " 
              target="_blank" 
              :href="item.url">
              {{item.name}}
            </a>
            <a class="navMainInner"
              v-if="!item.child.length && (!item.url || item.url == '') || item.entTChannelUuid =='01150003'" 
              :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/list/${item.entTChannelUuid}`">
              {{item.name}}
            </a>
            <a class="navMainInner"
              v-if="item.child.length && item.child[0].url && item.child[0].url !== '' "  
              :href="item.child[0].url">
              {{item.name}}
            </a>
            <a class="navMainInner"
              v-if="item.child.length && (!item.child[0].url || item.child[0].url == '') && item.entTChannelUuid !='011500050003' " 
              :href="item.child[0].catalog === 1 ? `/listPage/${item.child[0].entTChannelUuid}` : item.child[0].catalog === 2 ? `/list/${item.child[0].entTChannelUuid}` : `/list/${item.child[0].entTChannelUuid}`">
              {{item.name}}
            </a>
            <a class="navMainInner"
              v-if="item.child.length && (!item.child[0].url || item.child[0].url == '') && item.entTChannelUuid =='011500050003' " 
              :href="`/list/011500050003`">
              {{item.name}}
            </a>
          </span>
        </div>
        <div class="navMainRight">
          <div style="float: right">
            <span>
              <router-link to="/">
                <i class="iconfont">&#xe689;</i> 
                <span> &nbsp; 首页 &nbsp;>&nbsp; </span>
              </router-link>
            </span>
            <span v-if="viewSlideHdParent" class="txt1"><a :href="slideHdParentUrl">{{slideHdParentName}}</a> </span> 
            <span v-if="slideHdName2!=''" class="txt2"> &nbsp;>&nbsp; {{slideHdName2}} </span>
            <span v-if="isthird" class="txt3">&nbsp; > {{slideHdName1}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="ziBase">
      <div class="width_100 CLine"></div>
      <div class="ziContact">
        <div class="rowContact">
          <div class="newsDetails">
            <div id="ContentView">
              <div class="ContentViewTit">
                <div class="h3 text-center contentTitle" >
                  {{ newsitem.title }}
                </div>
              </div>
              <div style="background-color:#E7F4FA; height:2px; margin:10px auto"></div>
              <div class="newDiv">
                来源：<span class="spanp">{{newsitem.writer}}</span>
                作者：<span class="spanp">{{newsitem.author}}</span>
                时间：<span class="spanp">{{dayjs(newsitem.publishDate).format('YYYY-MM-DD')}}</span>
                点击：<span id="hits">{{newsitem.click}}</span>次
              </div>
              <div class="content_body nrstyle" v-html="newsitem.content"></div>
              <div class="clear"></div>
              <div class="des" style="text-align:left;border-bottom:0px;">
                <span>责任编辑：{{ newsitem.editor }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>发布时间：{{ dayjs(newsitem.publishDate).format('YYYY-MM-DD') }}</span>
              </div>
              <div class="tz">
                <div class="u" v-if="newsitem.upArtUuid !== '0'">
                  <a v-if="newsitem.entTChannelUuid == '00190001'" :href="`/detail/01150003/${newsitem.upArtUuid}`">上一篇：{{newsitem.upArtTitle}}</a>
                  <a v-else-if="newsitem.entTChannelUuid !== '00190001' && active == '011500050003'" :href="`/detail/011500050003/${newsitem.upArtUuid}`">上一篇：{{newsitem.upArtTitle}}</a>
                  <a v-else :href="`/detail/${newsitem.entTChannelUuid}/${newsitem.upArtUuid}`">上一篇：{{newsitem.upArtTitle}}</a>
                </div>
                  <div class="d" v-if="newsitem.downArtUuid !== '0'">
                  <a v-if="newsitem.entTChannelUuid == '00190001'" :href="`/detail/01150003/${newsitem.downArtUuid}`">下一篇：{{newsitem.downArtTitle}}</a>
                  <a v-else-if="newsitem.entTChannelUuid !== '00190001' && active == '011500050003'" :href="`/detail/011500050003/${newsitem.downArtUuid}`">上一篇：{{newsitem.downArtTitle}}</a>
                  <a v-else :href="`/detail/${newsitem.entTChannelUuid}/${newsitem.downArtUuid}`">下一篇：{{newsitem.downArtTitle}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getChildChannel, getChannelInfo, getArtInfo,increaseViews } from '@/api/common'
export default{
  data(){
    return {
      isthird:false,
      isClickid:'',
      isClickid2:'',
      parentId: '',
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName1: '',
      slideHdName2: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      slideHd: {
        title: '新闻动态',
      },
      slideList:[],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: [],
      newsitem:[],
      indexImg: '',
      listId: '',
      active:'',
    }
  },
  mounted(){
    var _this = this
    _this.active = _this.$route.params.parentId
    _this.listId = _this.$route.params.id
    _this.parentId = _this.$route.params.parentId
    _this.getChannelInfo()
    _this.getArtInfo()
    _this.increaseViews()
  },
  methods: {
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this

      let data = {
        applicationUuid: _this.webId,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let params1 = {
        channelUuid: _this.$route.params.parentId
      }
      getChannelInfo(params1).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          if( data.parentUuid=="0" ){
            _this.slideHdTitle = data.name
            _this.slideHdParentName = data.name
            if(data.fileName && data.fileName !== ''){
              _this.indexImg = data.fileName
            }
            else{
              _this.indexImg = require('../assets/image/partyBuilding.jpg')
            }
            return
          }else {
            _this.getChildChannel(data.parentUuid)
          }
          _this.slideHdName1 = data.name

          let params2 = {
            channelUuid: data.parentUuid
          }
          getChannelInfo(params2).then(res=>{
            const { code, data } = res
            if(code === 1 && data){
              if(data.parentUuid == '0'){
                // _this.getChildChannel(data.entTChannelUuid)
                _this.slideHdParentName = data.name
                _this.slideHdName2 = _this.slideHdName1
                _this.slideHdTitle = _this.slideHdName2
                _this.isthird = false
                if(data.fileName && data.fileName !== ''){
                  _this.indexImg = data.fileName
                }
                else{
                  _this.indexImg = require('../assets/image/partyBuilding.jpg')
                }
              }else{
                _this.isthird = true
                // _this.getChildChannel(data.parentUuid)
                _this.slideHdName2 = data.name
                let params3 = {
                  channelUuid: data.parentUuid
                }
                getChannelInfo(params3).then(res=>{
                  const { code , data } = res
                  if(code === 1 && data){
                    _this.slideHdParentName = data.name
                    _this.slideHdTitle = _this.slideHdName1
                  }
                })
              }
            }
          })
          let url = ''
          if (data.catalog === 1) {
            url = `/listPage/${_this.$route.params.parentId}`
          } else if (data.catalog === 2) {
            url = `/list/${_this.$route.params.parentId}`
          } else {
            url = `/list/${_this.$route.params.parentId}`
          }
          _this.slideHdParentUrl = url
          if (data.parentUuid == "0") {
            _this.viewSlideHdParent = false
            return
          }
        }
      })
    },
    getArtInfo() {
      var _this = this
      var applicationId = ''
      if (_this.$route.params.parentId == '01150003') {
        applicationId = _this.webIdGroup
      } else {
        applicationId = _this.webId
      }
      let data = {
        entTApplicationUuid: applicationId,
        entTArticleUuid: _this.$route.params.id
      }
      getArtInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsitem = data
        }
      })
    },
    increaseViews(){
      var _this = this
      let data = {
        entTArticleUuid: _this.$route.params.id
      }
      increaseViews(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          // _this.dataView = data
          // console.log(111,_this.dataView)
        }
      })
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.pageDetail {
    width: 100%;
    height: auto;
    overflow: hidden;
    min-height: 902px;
    margin-top: 72px;
}
.ziHead {
  width: 100%;
  height: 417px;
  overflow: hidden;
  /* background: url(../assets/image/business2.jpg) center no-repeat; */
  background-size: 100% 100%;
}
 .ziNav {
    height: 42px;
    border-bottom: 1px solid #cacaca;
    margin-top: 15px;
    width: 100%!important;
    margin-bottom: 10px;
}
.navMain {
    width: 1200px;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
}
.navMainInner {
  display: inline-block;
  padding: 0 4px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
  font-size: 17px;
}
.navMainLeft {
  width: 880px;
  float: left;
}
.spanActive{
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
  font-size: 17px;
}
.onActive a {
    border-bottom: 2px solid #0263b2;
}
.navMainRight span a{
    float: none;
    font-size: 13px;
    color: #a2a2a2;
    vertical-align: top;
}
.navMainRight {
    width: 320px;
    float: right;
    overflow: hidden;
    font-size: 13px;
    color: #a2a2a2;
    margin-top: 10px;
    line-height: 24px;
}
.navMainInner.active {
  border-bottom: 2px solid #0263b2;
}
.navMainRight span {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}
.navMainRight .textTitle {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}
.ziBase {
  width: auto;
    height: auto;
    overflow: hidden;
    background-color: #F2F2F2;
}
.CLine {
    height: 24px;
    overflow: hidden;
    background: url(../assets/image/CLine.png);
    position: absolute;
    z-index: 2;
    overflow: hidden;
}
.width_100 {
    width: 100% !important;
}
.ziContact {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.rowContact {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;
}
.ContentViewTit {
  text-align: center;
}
.newsDetails {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 1px 4px #333333;
  padding: 30px 50px;
  background-color: #ffffff;
  line-height: 24px;
  box-sizing: border-box;
}
.newsDetails .auto_div {
  width: auto;
  height: auto;
  overflow: hidden;
}
.newsDetails .nrstyle {
  text-indent: 2em;
  text-align: justify;
  font-family: '宋体';
  font-size: 18px;
  line-height: 30px;
}
.ContentViewTit {
  text-align: center;
}
.newsDetails .clear {
  width: 100%;
  height: 10px;
  clear: both;
  overflow: hidden;
}
.newsDetails #ContentView .des {
  font-size: 14px;
  height: 40px;
  color: #caccce;
  line-height: 30px;
  margin: 10px 0px;
  padding-bottom: 10px;
}
.newsDetails #ContentView .tz {
  border-top: 1px #f7ebec solid;
  padding: 10px 0px;
  margin-top: 10px;
  text-align: left;
}
.newsDetails #ContentView .tz a {
  font-size: 14px;
  display: inline-block;
  padding: 5px 0px;
}
.newsDetails a {
  color: #333;
}
.newsDetails a,
a {
  text-decoration: none;
}
.newDiv{
  font-size: 14px; 
  color: #999; 
  text-align: center; 
  margin: 16px auto;
}
.spanp{
  margin-right: 20px;
}
.contentTitle{
  margin-top: 20px; 
  margin-bottom: 10px; 
  font-size: 24px;
  line-height:36px;
  padding-bottom: 8px;
}

@media (max-width: 1300px) {
  .rowContact{
    width: 1000px;
  }
  .newsDetails {
    width: 1000px;
  }
  .navMain {
    width: 1000px;
  }
 .navMainLeft {
    width: auto;
  }
  .navMainRight {
    width: auto;
  }
  .content_body video {
    max-width: 96% !important;
  }
  .content_body img {
    max-width: 96% !important;
    height: auto !important;
  }
   .pageDetail {
    margin-top: 0px;
}
}
@media (max-width: 992px) {
  .rowContact{
    width: auto;
  }
  .newsDetails {
    width: 96%;
  }
  .newsDetails {
    padding: 20px 10px;
  }
  .newsDetails #ContentView {
    padding: 20px 10px;
  }
  .content_body img {
    max-width: 88% !important;
    height: auto !important;
  }
  .ziHead {
    height: 362px;
  }
  .navMain {
    width: 96%;
  }
 .navMainLeft {
    width: auto;
  }
  .navMainRight {
    width: auto;
  }
   .pageDetail {
    margin-top: 0px;
}
}
@media (max-width: 768px) {
  .pageDetail {
    margin-top: 0px;
}
   .ziHead {
    height: 200px;
    margin-top: 0px;
  }
  .navMainRight{
    display: none;
  }
  .rowContact{
    width: auto;
  }
  .newsDetails {
    width: 96%;
  }
  .content_body img {
    max-width: 90% !important;
    margin-left: -4% !important;
    margin-right: auto !important;
    height: auto !important;
  }
   .navMain {
    width: 96%;
  }
  .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .newsDetails #ContentView {
      padding: 10px 4px;
  }
  .newsDetails {
      padding: 10px 10px;
  }
  .ziContact {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}
.newDiv{
  font-size: 12px; 
  color: #999; 
  text-align: center; 
  margin: 8px auto;
}
.spanp{
  margin-right: 4px;
}
.contentTitle{
  margin-top: 6px;
    margin-bottom: 6px;
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 0px;
}
}

</style>
